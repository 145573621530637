$orange: #f07d00;
$lightOrange: #f08b20;
$darkBlue: #3149a5;
$lightBlue: #4fa9d1;
$green: #17b566;

$shortGrey: #70707036;
$grey99: #fcfcfc;
$grey99LowOpacity: #fcfcfc38;
$grey: #707070;
$lightGrey: #acacac;
$lightLightGrey: #d3d3d3;
$darkGrey: #616060;
$pastelRed: #ff6961;


$blue-app: #004187;

.captcha {
  width: 287px !important;
}
